var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9292c58-20250120T131618-prod"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { Breadcrumb, BreadcrumbHint, Breadcrumbs, init } from '@sentry/nextjs';

import { createMessage } from '@collab/libs/sentry';
import { setLogger } from '@collab/utils/logger';

import { CONFIG, SENTRY_ENABLED } from 'libs/sentry/sentry.config';
import SentryLogger from 'libs/sentry/sentry.logger';

const beforeBreadcrumb = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => {
  if (breadcrumb.category === 'ui.click' && hint?.event) {
    breadcrumb.message = createMessage(hint.event as Event);
  }

  return breadcrumb.message ? breadcrumb : null;
};

if (SENTRY_ENABLED) {
  setLogger(new SentryLogger());

  init({
    ...CONFIG,
    integrations: [
      new Breadcrumbs({
        dom: true,
        history: true,
        fetch: false,
        xhr: false,
      }),
    ],
    beforeBreadcrumb,
  });
}
