// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-properties */
import { ConsentGroups } from '@vcc-package/storage/consent';
import { Tracker } from '@volvo-cars/tracking';
import Cookies from 'js-cookie';

import { environment } from './environment';

const tracker = new Tracker();

declare global {
  interface Window {
    OptanonWrapper?: () => void;
    OptanonActiveGroups?: string;
  }
}

type PageEventProps = {
  event: string;
  page: string;
};

type GtmMetric = {
  id: string;
  name: string;
  value: number;
};

const PAGEVIEW_EVENT = 'pageview';

/**
 * Creates global method OptanonWrapper that is called by OneTrust
 * on pageload as well as Consent changes.
 */
export const initOneTrustWrapper = () => {
  window.OptanonWrapper = () => {
    moveAllowAllButton();
    gtmPageView(window.location.pathname);
    removeDisallowedGaCookies();
  };
};

export const gtmPageView = (url: string) => {
  const pageEvent: PageEventProps = {
    event: PAGEVIEW_EVENT,
    page: url,
  };

  window.dataLayer.push(pageEvent);
};

const metricsToTrack = ['TTFB', 'FCP', 'FID', 'CLS', 'Next.js-hydration'];

export const gtmMetrics = ({ name, id, value }: GtmMetric) => {
  const metricData = {
    eventCategory: 'metrics',
    eventAction: name,
    eventLabel: id,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
  };

  if (metricsToTrack.includes(name)) {
    tracker.nonInteraction(metricData);
  }
};

const removeDisallowedGaCookies = () => {
  if (hasConsentForStatisticsCookies()) return;

  const gaCookiesPrefix = '_ga';
  const cookiesArray = document.cookie.split(';');

  cookiesArray.forEach((cookie) => {
    if (cookie.trim().startsWith(gaCookiesPrefix)) {
      const [cookieName] = cookie.split('=');

      Cookies.remove(
        cookieName.trim(),
        environment.IS_PROD ? { domain: '.volvocars.com' } : {},
      );
    }
  });

  cookiesArray.map((cookie) => {
    if (cookie.trim().startsWith(gaCookiesPrefix)) {
      const [cookieName] = cookie.split('=');

      Cookies.remove(
        cookieName.trim(),
        environment.IS_PROD ? { domain: '.volvocars.com' } : {},
      );
    }

    return;
  });
};

const hasConsentForStatisticsCookies = () =>
  window.OptanonActiveGroups?.includes(`,${ConsentGroups.STATISTICS},`);

const moveAllowAllButton = () => {
  const acceptRecommendedBtn = document.getElementById(
    'accept-recommended-btn-handler',
  );
  if (acceptRecommendedBtn) {
    const btnContainer = document.querySelector('.ot-btn-container');
    if (btnContainer) {
      btnContainer.appendChild(acceptRecommendedBtn);
    }

    const acceptBtn = document.querySelector('#onetrust-accept-btn-handler');
    const pcBtn = document.getElementById('onetrust-pc-btn-handler');
    if (acceptBtn && pcBtn) {
      acceptBtn.insertAdjacentElement('afterend', pcBtn);
    }

    const consentSdk = document.getElementById('onetrust-consent-sdk');
    if (consentSdk) {
      consentSdk.setAttribute('data-nosnippet', '');
    }
  }
};
