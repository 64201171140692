import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { useMaintenance } from '@collab/features/maintenance';
import { Popup } from '@collab/features/popup';

const InfoBanner = dynamic(() => import('@collab/molecules/InfoBanner'));
const DefaultPageLayout = dynamic(() => import('./DefaultPageLayout'));
const HeaderLessLayout = dynamic(() => import('./HeaderLessLayout'));
const SimplePageLayout = dynamic(() => import('./SimplePageLayout'));

export type PageMods = {
  darkTheme?: boolean;
  withLayout?: boolean;
  seoNoIndex?: boolean;
  noHeader?: boolean;
};

export type ComponentWithPageMods<T = Record<string, unknown>> = React.FC<T> & {
  pageMods: PageMods;
};

type LayoutProps = { header: any; footer: any };

const defaultPageMods = { withLayout: true };

const PageLayout: React.FC<{
  layoutProps: LayoutProps;
  pageMods?: PageMods;
  children: React.ReactNode;
}> = ({ pageMods, layoutProps: { header, footer }, children }) => {
  const { withLayout, noHeader } = { ...defaultPageMods, ...pageMods };
  const { hasMaintenance, message } = useMaintenance();
  const [showInfoBanner, setShowInfoBanner] = useState(hasMaintenance);

  useEffect(() => {
    setShowInfoBanner(hasMaintenance);
  }, [hasMaintenance]);

  return (
    <>
      {pageMods?.seoNoIndex ? <NextSeo noindex /> : undefined}
      {showInfoBanner && (
        <InfoBanner onDismiss={() => setShowInfoBanner(false)}>
          {message ?? 'We are experiencing some issues right now.'}
        </InfoBanner>
      )}
      {withLayout ? (
        <DefaultPageLayout
          headerProps={header}
          footerProps={footer}
          showInfoBanner={showInfoBanner}
        >
          {children}
        </DefaultPageLayout>
      ) : noHeader ? (
        <HeaderLessLayout>{children}</HeaderLessLayout>
      ) : (
        <SimplePageLayout>{children}</SimplePageLayout>
      )}
      <Popup />
    </>
  );
};

export default PageLayout;
